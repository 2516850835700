import { createRouter, createWebHistory,createWebHashHistory , RouteRecordRaw } from "vue-router";
import HomeView from "../views/index.vue" ;
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component:HomeView
    //component: () => import("../views/CreateView.vue")
  },

];

const router = createRouter({
  history: createWebHashHistory(), //createWebHistory(process.env.BASE_URL)
  routes,
});

router.beforeEach((to, from, next) => {
  // if (to.meta.removeBackground) {
  //   document.body.style.background = 'none';
  // } else {
  //   document.body.style.background = ''; // 恢复默认背景样式
  // }

  next();
});
export default router;
