<template>
  <div class="container">
    <div class="lan-container">
      <div class="lan-col" @click.stop="changeLanguage">
        {{ locale == "en" ? "English" : "中文" }}
        <img src="../assets/images/repeat.png" alt="" />
      </div>
    </div>

    <div class="title-col">{{ $t("title1") }}</div>
    <div class="time-col">2024.02.23</div>
    <div class="msg-col fir-col">
      {{ $t("msg1") }}
    </div>
    <div class="title-col child-title">{{ $t("title2") }}</div>
    <div class="msg-col" style="margin-top: 18px; line-height: 20px">
      <p>{{ $t("msg2") }}</p>
      <p>{{ $t("msg3") }}</p>
      <p>{{ $t("msg4") }}</p>
    </div>
    <div class="title-col child-title">
      {{ $t("title3") }}
    </div>
    <div class="media-col">
      <div class="img-col">
        <img src="../assets/images/qr.png" alt="" />
      </div>
      <div class="btn-col" v-if="false">{{ $t("btnmsg") }}</div>
      <div class="title2-col">{{ $t("copymsg") }}</div>
      <div class="msg2-col" @click.stop="copyText">
        {{ address }}
        <div class="copy-col">
          <img src="../assets/images/copy.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { Toast } from "vant";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
const { t, locale } = useI18n();
const router = useRouter();
const address = "1C9RK87AweCBzdkDwo8eb3BUdtXr6RdKPv";

onMounted(() => {});

function changeLanguage() {
  locale.value == "en" ? (locale.value = "zh-CN") : (locale.value = "en");
}

//复制文本内容
function copyText() {
  console.log("点击复制");
  const textarea = document.createElement("textarea");
  textarea.value = address;
  document.body.appendChild(textarea);

  // 选择文本并复制到剪贴板
  textarea.select();
  document.execCommand("copy");
  // Toast({
  //   message: t("copy"),
  //   duration: 1000,
  // });

  Toast(t("copy"));

  // 清理临时元素
  document.body.removeChild(textarea);
}
</script>

<style lang="less" scoped>
.container {
  padding: 0 16px;
  color: #333;
  padding-bottom: 36px;
  background: #f5f5f5;
  padding-top: 16px;
}
.title-col {
  font-size: 24px;
  font-weight: bold;
  margin-top: 16px;
  line-height: 36px;
}
.child-title {
  font-size: 18px;
  margin-top: 28px;
  line-height: 24px;
}
.time-col {
  font-size: 14px;
  opacity: 0.5;
  margin-top: 8px;
}
.msg-col {
  font-size: 16px;
  opacity: 0.6;
  line-height: 36px;
  p {
    margin-bottom: 16px;
  }
  p:last-child {
    margin-bottom: 0;
  }
}
.fir-col {
  margin-top: 36px;
}
.media-col {
  background: #f3f3f3;
  border-radius: 10px;
  padding: 23px 28px 30px;
  margin-top: 23px;
}
.btn-col {
  height: 42px;
  border-radius: 24px;
  background: #439bf4;
  color: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
}
.title2-col {
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  margin-top: 24px;
}
.msg2-col {
  margin-top: 12px;
  font-size: 13px;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .copy-col {
    width: 20px;
    height: 20px;
    // margin-left: 4px;
  }
}
.lan-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 16px;
}
.lan-col {
  // width: 56px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;

  img {
    width: 18px;
    height: 18px;
    margin-left: 4px;
  }
}
img {
  width: 100%;
  height: 100%;
}
</style>
