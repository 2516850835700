import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import i18n from "@/contexts/Localisation/language";
import {
  Toast,
  Picker,
  Popup,
  Icon,
  Tab,
  Tabs,
  Search,
  Field,
  CellGroup,
  Dialog
} from "vant";
import "vant/lib/index.css";

// import Vconsole from "vconsole";
// const vConsole = new Vconsole();

createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .use(Toast)
  .use(Picker)
  .use(Popup)
  .use(Icon)
  .use(Tab)
  .use(Tabs)
  .use(Search)
  .use(Field)
  .use(CellGroup)
  .use(Dialog)
  .mount("#app");




