<template>
  <router-view v-if="isRouterAlive" />
</template>
<script setup>
import { useStore } from "vuex";
import { onMounted, ref, provide, nextTick } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useI18n } from "vue-i18n";

const store = useStore();

let userAddress = ref("");
const router = useRouter();
const route = useRoute();
//window.particle_lite = particle_lite;

const { t } = useI18n();
const { locale } = useI18n();

let isRouterAlive = ref(true);



onMounted(async () => {

});

const reload = () => {
  isRouterAlive.value = false;
  nextTick(() => {
    isRouterAlive.value = true;
  });
};

provide("reload", reload);


</script>
<style lang="less">
@import url(./assets/css/style.less);
</style>
